// Use dev API url if API url is not present in environment variables.
export const rule5BaseUrl = process.env.REACT_APP_API_BASE_URL
  ? process.env.REACT_APP_API_BASE_URL
  : "https://dev-api.rule5.io/v1";
// "http://localhost:8080/v1";
export const rule5AppUrl = rule5BaseUrl
  .substring(0, rule5BaseUrl.length - 3)
  .replace("8080", "3000")
  .replace("api", "app");
export const rule5Env =
  rule5BaseUrl === "https://dev-api.rule5.io/v1"
    ? "dev"
    : rule5BaseUrl === "https://staging-api.rule5.io/v1"
    ? "staging"
    : rule5BaseUrl === "https://api.rule5.io/v1"
    ? "prod"
    : "dev";
export const rule5properties = {
  // License Keys
  MUIXProLicenseKey:
    "60b3f04e250cb5e4163083005b9fc43fTz03NzM0NixFPTE3Mjk4MzUzMzYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
  RapidAPILicenseKey: "86c322a1a4mshea74f9cd5ffeb76p1949ecjsn656df69dcaf0",

  // Artifact Downloader
  getSecureFile: rule5BaseUrl + "/downloads",

  // Opportunity APIs
  getOpportunityColumns: rule5BaseUrl + "/columns",
  getUserOpportunityConfigs: rule5BaseUrl + "/configs",
  updateUserOpportunityConfigs: rule5BaseUrl + "/configs",
  getOpportunityList: rule5BaseUrl + "/opportunities",
  shareOpportunities: rule5BaseUrl + "/opportunities/share",
  opptySearch: rule5BaseUrl + "/opportunities/search",
  skinny: rule5BaseUrl + "/opportunities/skinny",
  opptyConsumption: rule5BaseUrl + "/opportunities/consumption",
  getUserOpportunities: rule5BaseUrl + "/opportunities/users",

  // Card API
  getCard: rule5BaseUrl + "/cards",
  sfdcSync: rule5BaseUrl + "/cards/sync-sfdc",

  // Card Types API
  getCardTypes: rule5BaseUrl + "/card-types",

  // Export API
  getExportApi: rule5BaseUrl + "/export",
  exportEmail: rule5BaseUrl + "/export/email",

  // Rapid API Endpoints
  rapidAPIHost: "bb-finance.p.rapidapi.com",
  getStockPrice: "https://bb-finance.p.rapidapi.com/market/get-compact",
  getPriceChart: "https://bb-finance.p.rapidapi.com/market/get-chart",
  getStats: "https://bb-finance.p.rapidapi.com/stock/get-statistics",

  // Authentication API base
  authApiUrl: rule5BaseUrl + "/auth",

  // User APIs
  usersApiUrl: rule5BaseUrl + "/users",
  getUsers: rule5BaseUrl + "/users/get-users",
  updateUsers: rule5BaseUrl + "/users/update-user",
  userProfileApiUrl: rule5BaseUrl + "/users/profile",
  usersAndOpportunitiesExport: rule5BaseUrl + "/opportunities/users",

  // Invitation Flow
  inviteEmailList: rule5BaseUrl + "/users/invite-users",

  // JIRA Requests
  requests: rule5BaseUrl + "/requests",

  // Make Data OPS Request (JIRA Ticket)
  requestDataOPS: rule5BaseUrl + "/requests",

  // Companies
  companies: rule5BaseUrl + "/companies",

  // Orgs
  orgFunctionalAreas: rule5BaseUrl + "/orgs/functional-areas",
  orgDetails: rule5BaseUrl + "/orgs/user",

  // Connections
  connections: rule5BaseUrl + "/connections/search",
  offDomainConnectionRequests: rule5BaseUrl + "/off-domain-connection-requests",

  // Values
  values: rule5BaseUrl + "/values",

  // Feedback
  feedback: rule5BaseUrl + "/feedback",

  // Person api
  requestPerson: rule5BaseUrl + "/persons",

  // Get management quotes
  getManagementQuotes: rule5BaseUrl + "/persons/get-management-quotes",

  // Key people profile (core)
  coreKeyPeopleProfile: rule5BaseUrl + "/key-people-profile",

  // Accounts
  accounts: rule5BaseUrl + "/accounts",
  accountMatch: rule5BaseUrl + "/accounts/match",
  accountUpdate: rule5BaseUrl + "/accounts/upsert-id",

  // DET APIs
  detGetUsers: rule5BaseUrl + "/det/users",
  detUpdateUser: rule5BaseUrl + "/det/users/update-user",
  detCreateUser: rule5BaseUrl + "/det/users/invite-user",
  detCards: rule5BaseUrl + "/det/cards",
  detResearch: rule5BaseUrl + "/det/research",
  detKeyPeopleProfile: rule5BaseUrl + "/det/key-people-profile",
  detResearchUpversion: rule5BaseUrl + "/det/research/upversion",
  detCompanies: rule5BaseUrl + "/det/companies",
  detStocks: rule5BaseUrl + "/det/stocks",
  detCompaniesImage: rule5BaseUrl + "/det/companies/upload-images",
  detOrgs: rule5BaseUrl + "/det/orgs",
  detOrgsImage: rule5BaseUrl + "/det/orgs/upload-images",
  detPersonsUpload: rule5BaseUrl + "/det/uploads",
  detDownloads: rule5BaseUrl + "/det/downloads",
  detCardTypes: rule5BaseUrl + "/det/card-types",
  detValues: rule5BaseUrl + "/det/values",
  detRimsPeerGroup: rule5BaseUrl + "/det/trends/rims-peer-group",
  detRevenueNetIncome: rule5BaseUrl + "/det/trends/revenue-net-income",
  detD3CPeerGroup: rule5BaseUrl + "/det/trends/d3c-peer-group",
  detCardData: rule5BaseUrl + "/det/cards/card-data",
  detNewsOnDemand: rule5BaseUrl + "/det/pipelines/update-news",

  // Notification preferences.
  getNotificationPreferences:
    rule5BaseUrl + "/users/get-notification-preferences",
  updateNotificationPreferences:
    rule5BaseUrl + "/users/update-notification-preferences",

  // GPT Preferences.
  gptPreferences: rule5BaseUrl + "/users/gpt-preferences",

  // Card data api, for core app (DET is separate, above)
  cardData: rule5BaseUrl + "/cards/card-data",

  // Notifications endpoints
  notifications: rule5BaseUrl + "/notifications",

  // Emails
  emailSend: rule5BaseUrl + "/email/send",

  // Contact Support
  contactSupport: rule5BaseUrl + "/contact-support",

  // GPT/conversations
  gptCompletion: rule5BaseUrl + "/gpt/completions",
  conversations: rule5BaseUrl + "/conversations",
  conversationFeedback: rule5BaseUrl + "/conversations/feedback",
  generateEmail: rule5BaseUrl + "/gpt/emails",
  emailConversation: rule5BaseUrl + "/conversations/email",
  suggestedQuestions: rule5BaseUrl + "/gpt/suggested-questions",

  // Prompt Books
  prompts: rule5BaseUrl + "/prompts",
  promptsSequences: rule5BaseUrl + "/prompts/sequences",
  promptBooks: rule5BaseUrl + "/prompt-books",
  promptBookPrompts: rule5BaseUrl + "/prompt-books/prompts",

  // Labels
  labels: rule5BaseUrl + "/labels",
  promptLabels: rule5BaseUrl + "/prompts/labels",

  // Content
  contents: rule5BaseUrl + "/contents",
  contentCategories: rule5BaseUrl + "/content-categories",

  // User message channels
  userMessageChannels: rule5BaseUrl + "/users/message-channels",

  // Slack integration
  slackInstall: `https://slack.com/oauth/v2/authorize?scope=channels%3Aread%2Cgroups%3Aread%2Cim%3Aread%2Cmpim%3Aread%2Cchat%3Awrite&user_scope=groups%3Awrite.invites%2Cgroups%3Awrite%2Cgroups%3Aread%2Cchannels%3Aread%2Cim%3Aread%2Cmpim%3Aread%2Cchannels%3Awrite.invites&redirect_uri=${encodeURIComponent(
    rule5AppUrl
  )}%2Fmain%2Fsettings%2Fintegrations&client_id=2241177673206.6222142710641`,
  slackCallback: rule5BaseUrl + "/integrations/slack-callback",
  slackListChannels: rule5BaseUrl + "/integrations/slack-channels",

  // Monitor
  agents: rule5BaseUrl + "/agents",
  agentResults: rule5BaseUrl + "/agent-results",
  agentPeople: rule5BaseUrl + "/agent-people",
  agentAccounts: rule5BaseUrl + "/agent-accounts",
  runAccountAgent: rule5BaseUrl + "/pipelines/monitor-account-agents",
  runPeopleAgent: rule5BaseUrl + "/pipelines/monitor-people-agents",
  jobChangeLeads: rule5BaseUrl + "/job-change-leads",
  syncAccounts: rule5BaseUrl + "/agents/sync-accounts",

  // Subscriptions
  checkoutSession: rule5BaseUrl + "/user-subscriptions/checkout-session",
  invoices: rule5BaseUrl + "/user-subscriptions/invoices",
  portalSession: rule5BaseUrl + "/user-subscriptions/portal-session",

  // API keys
  apiKeys: rule5BaseUrl + "/users/api-keys",
};
